import React, { useEffect } from 'react';
import { StaticImage } from "gatsby-plugin-image";
import '../styles/accueil.scss'
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Link } from 'gatsby';

export default function Accueil() {

    useEffect(() => {
        if (window.innerWidth > 900) {
            onmousemove = (ev) => {
                const cursor = document.querySelector('#id_cursor')
                const cursorPoint = document.querySelector('#id_cursor_point')
    
                cursor.style.top = `${ev.pageY}px`
                cursor.style.left = `${ev.pageX}px`
                cursorPoint.style.top = `${ev.pageY}px`
                cursorPoint.style.left = `${ev.pageX}px`
            }
        }
    }, [])

    const OverBlurbs1 = () => {
        document.getElementById('module_3').className = 'module_3_bg_1';
    }

    const OverBlurbs2 = () => {
        document.getElementById('module_3').className = 'module_3_bg_2';
    }

    const OverBlurbs3 = () => {
        document.getElementById('module_3').className = 'module_3_bg_3';
    }

    const OverBlurbs4 = () => {
        document.getElementById('module_3').className = 'module_3_bg_4';
    }

    const LeaveBlurbs = () => {
        document.getElementById('module_3').className = '';
    }

    const classCursorOver = () => {
        document.getElementById('id_cursor').setAttribute("style", "width:400px;height:400px;");
    }

    const classCursorLeave = () => {
        document.getElementById('id_cursor').setAttribute("style", "width:100px;height:100px;");
    }

    return (
        <Layout>
            <Seo title="Accueil" />
            <span id="id_cursor" className="cursor" />
            <span id="id_cursor_point" />
            <div id="accueil">
                <section className="module_1">
                    <h1>Pixel Développement</h1>
                    <h2>Créateur de sites Internet</h2>
                </section>

                <section className="module_2">
                    <div className="container">
                        <div className="width50">
                            <h3>A propos de Pixel Développement</h3>
                            <p>
                                <strong>Pixel Développement</strong> est une <strong>Société de création de sites
                                    Internet</strong> créée en juillet 2018, j'ai conçu plusieurs sites Internet pour
                                différentes sociétés : coach sportif, architecte, maçon, transport ambulances et taxis,
                                orthoptiste, chambre d'hôte...
                            </p>
                            <p>
                                <strong>Passionné par l'informatique</strong> depuis mon plus jeune âge, informaticien de
                                métier, créateur de sites Internet et amoureux du travail bien fait, je mets tout mon
                                savoir-faire à votre service, afin de vous proposer un site Internet à votre image.
                            </p>
                        </div>
                        <div className="width50 logo">
                            <StaticImage
                                className=""
                                src="../images/logob128.png"
                                alt="Logo Pixel Developpement"
                                placeholder="blurred"
                            />
                        </div>
                    </div>
                </section>

                <section id="module_3">
                    <h3>Mes services</h3>
                    <div className="container stretch all_blurbs">
                        <div className="blurbs blurb1" onMouseOver={OverBlurbs1} onMouseLeave={LeaveBlurbs}>
                            <h4>Sites internet</h4>
                            <p>Sites vitrine</p>
                            <p>Sites e-commerce</p>
                            <p>Refonte de sites existants</p>
                        </div>
                        <div className="blurbs blurb2" onMouseOver={OverBlurbs2} onMouseLeave={LeaveBlurbs}>
                            <h4>Référencement SEO</h4>
                            <p>
                                Je travaille le référencement naturel de votre site pour vous faire apparaître en bonne
                                position dans les résultats des moteurs de recherche
                            </p>
                        </div>
                        <div className="blurbs blurb3" onMouseOver={OverBlurbs3} onMouseLeave={LeaveBlurbs}>
                            <h4>Hébergement</h4>
                            <p>
                                Je m'occupe de l'hébergement de votre site Web ainsi que vos boîtes email professionnelles
                            </p>
                        </div>
                        <div className="blurbs blurb4" onMouseOver={OverBlurbs4} onMouseLeave={LeaveBlurbs}>
                            <h4>Maintenance</h4>
                            <p>
                                Je peux vous proposer d'assurer les sauvegardes, la maintenance et les mises à jour de
                                votre site
                            </p>
                        </div>
                    </div>
                </section>

                <section className="module_4">
                    <div>
                        <h3>Les étapes de la création d'un site Internet</h3>
                    </div>
                    <div className="">
                        <div className="blurbs-large">
                            <h4>Premier Contact</h4>
                            <p>Nous prenons rendez-vous afin de mettre en place un cahier des charges pour votre projet
                                et vos attentes.</p>
                            <p>Nous parlons de votre métier, ce que vous proposez à vos clients, et nous mettons en
                                avant vos points forts afin de booster votre visibilité</p>
                        </div>
                        <div className="blurbs-large">
                            <h4>Conception</h4>
                            <p>Je commence la création de votre site Internet, vous aurez un accès visuel sur le site
                                tout au long de sa conception, et nous ferons le point fréquemment pour répondre au
                                mieux à vos attentes.</p>
                            <p>Je gère la rédaction de vos textes et la recherche de vos visuels.</p>
                            <p>La création d'un site Internet prend de 2 semaines à 2 mois selon le projet.</p>
                        </div>
                        <div className="blurbs-large">
                            <h4>Dernières modifications</h4>
                            <p>Avant la mise en ligne de votre site, nous débrieferons ensemble et je ferai les
                                modifications nécessaires pour satisfaire vos attentes.</p>
                        </div>
                        <div className="blurbs-large">
                            <h4>Site terminé !</h4>
                            <p>Mise en ligne de votre site Internet.</p>
                            <p>Vous êtes maintenant visible sur la toile !</p>
                        </div>
                    </div>
                </section>

                <div className="module_5" onMouseOver={classCursorOver} onMouseLeave={classCursorLeave} >
                    <h2>Commençons votre projet</h2>
                    <Link to="/contact" className="primary_button_transparent">Contactez-moi</Link>
                </div>

                <section className="module_6">
                    <h2>Ils me font confiance</h2>
                    <div className="images">
                        <StaticImage
                            className=""
                            src="../images/clients/jrsport.jpg"
                            alt=""
                            placeholder="blurred"
                        />
                        <StaticImage
                            className=""
                            src="../images/clients/fly_in_sky.jpg"
                            alt=""
                            placeholder="blurred"
                        />
                        <StaticImage
                            className=""
                            src="../images/clients/rf_maconnerie.jpg"
                            alt=""
                            placeholder="blurred"
                        />
                        <StaticImage
                            className=""
                            src="../images/clients/sas_kpi.jpg"
                            alt=""
                            placeholder="blurred"
                        />
                        <StaticImage
                            className=""
                            src="../images/clients/tiny_house_tarentaise.jpg"
                            alt=""
                            placeholder="blurred"
                        />
                        <StaticImage
                            className=""
                            src="../images/clients/arly_ambulances-arly_taxi.jpg"
                            alt=""
                            placeholder="blurred"
                        />
                    </div>
                </section>
            </div>
        </Layout>
    )
}